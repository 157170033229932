// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import $ from 'jquery';
import 'foundation-sites';
import 'foundation-sites/js/foundation.core';
import 'foundation-sites/js/foundation.util.mediaQuery';
import 'foundation-sites/js/foundation.toggler';
import 'foundation-sites/js/foundation.sticky';
import "select2"


Rails.start()
Turbolinks.start()
ActiveStorage.start()


$(document).on('turbolinks:load', function() {
  $(document).foundation()
  $('.select2').select2();

  $('#new_expense_date').on('keyup', function (e) {
    let val = e.target.value
    console.log(val)
    $('#new_expense_attachment_accounting_date').val(val)
  });

  $('.currency-select').change(function(e){
    if($('.currency-select').val() == 'eur'){
      $('.conversion-rate').val('1.95583')
    } else {
      $('.conversion-rate').val('1')
    }
  });

  $('.sidebar-accordion .accordion-title').click(function(){
    const anchor = $(this).attr('href')
    $('.accordion-content a').attr('href', function (_, oldHref) {
      return oldHref.split('#')[0] + anchor;
    });
  });

  if (window.location.hash != undefined) {
    $('.accordion-content a').attr('href', function (_, oldHref) {
      return oldHref.split('#')[0] + window.location.hash;
    });
  }

  $('#close-drawer, .close-drawer').click(function(e) {
    e.preventDefault(e);
    $('#drawer').removeClass('open');
    $('#main').removeClass('drawer-open');
  });
  $('#open-drawer').click(function(e) {
    e.preventDefault(e);
    $('#drawer').addClass('open');
    $('#main').addClass('drawer-open');
  });

});